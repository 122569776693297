<!-- Acid test for uniliver -->
<template>
  <div class="container-stats">
    <div class="container-statistics">
      <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
        <li>
          <router-link to="/solutions/ul/platform/models">
            <span>Model</span>
          </router-link>
        </li>
        <li>
          <router-link to="/solutions/ul/platform/stat">
            <span>Sale Statistics</span>
          </router-link>
        </li>
        <li class="ProximaNovaBold">Acid Test</li>
      </ul>
    </div>

    <div class="container-line-chart w-100">
      <div class="title">Acid Test</div>
      <div class="col-md-12 col-lg-12 col-sm-12 my-4">
        <div class="card pb-5 pb-lg-5 h-100">
          <div
            class="wrapper content-center px-3 pt-4"
            style="justify-content: space-between"
          >
            <h5 class="card-heading">Acid Test</h5>
          </div>
          <div class="row perf-card-wrapper">
            <div
              v-for="(data, index) in overallDetailsData"
              :key="index"
              class="col-12 col-md-4 d-flex justify-content-center"
            >
              <div class="perf-card">
                <Statistics
                  :data="data"
                  :index="index"
                  :specialChar="false"
                  :specialCharText="''"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right my-5 w-100 col-md-12 col-lg-12 col-sm-12 my-4">
        <w-button
          :buttonText="'Continue'"
          class="my-5"
          :buttonLoader="'normal'"
          @buttonClicked="nextToTheInsights"
        >
        </w-button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import Statistics from "@/components/Statistics.vue";
export default {
  name: "Stats",
  components: {
    Statistics,
    "w-button": Button,
    // "w-white-dropdown": WhiteDropdown,
  },
  data() {
    return {
      overallDetailsData: [
        { label: "Actual Sales", value: "737.6Cr" },
        { label: "Predicted Sales", value: "700.1Cr" },
        { label: "Error %", value: "5.08%" },
      ],
    };
  },
  methods: {
    nextToTheInsights() {
      this.$router.push("/solutions/ul/platform/insights");
    },
  },
  created() {},
};
</script>

<style lang="css" scoped>
.container-stats {
  width: 93%;
  margin: 71px auto 0 auto;
}

.container-statistics {
  padding-top: 1px;
}

.title {
  font-family: ProximaNovaBold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin-top: 48px;
  margin-bottom: 32px;
}

.wrapper-statistics {
  box-shadow: 0px 0px 16px -13px rgb(0 0 0);
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.card-heading {
  margin: 20px 0px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}
.item {
  display: flex;
  width: 20%;
  position: relative;
}
.perf-card-wrapper {
  margin: 0px 24px;
}

.perf-card {
  width: 277px;
  height: 97px;
  box-shadow: 0 0 8px 0 #c9ced5;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-top: 44px;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
